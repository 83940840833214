<template>
    <li class="list-group-item d-flex flex-nowrap py-2 px-2">
        <div class="pr-2">
            <div class="btn-group">
                <button class="btn btn-sm btn-light border"
                        v-if="index > 0"
                        v-on:click="moveUp">
                    ⬆️
                </button>
                <button class="btn btn-sm btn-light border"
                        v-if="index < length - 1"
                        v-on:click="moveDown">
                    ⬇️
                </button>
            </div>
        </div>
        <div class="d-flex align-items-center flex-grow-1 pr-2">
            <p class="mb-0"
               v-if="!isInEditMode">
                <color-dot class="mr-1"
                           size="sm"
                           v-bind:color="category.color">
                </color-dot>
                <span class="badge badge-pill badge-primary mr-1"
                      style="font-size: 85%">
                    {{ categoryRegion.name }}
                </span>
                <span>
                    {{ category.name }}
                </span>
            </p>
            <div class="d-flex flex-nowrap w-100"
                 v-else>
                <b-form-input class="input-color mr-1"
                              size="sm"
                              type="color"
                              v-model="categoryColor">
                </b-form-input>
                <b-form-input class="flex-grow-1"
                              size="sm"
                              type="text"
                              v-model="categoryName">
                </b-form-input>
            </div>
        </div>
        <div>
            <div class="btn-group">
                <button class="btn btn-sm btn-light border"
                        v-if="isInEditMode"
                        v-on:click="edit">
                    💾
                </button>
                <button class="btn btn-sm btn-light border"
                        v-if="isInEditMode"
                        v-on:click="cancel">
                    ❌
                </button>
                <button class="btn btn-sm btn-light border"
                        v-if="!isInEditMode"
                        v-bind:class="{ 'btn-loading': isLoading, }"
                        v-on:click="toggle">
                    {{ isActive ? "🟢" : "🔴" }}
                </button>
                <button class="btn btn-sm btn-light border"
                        v-if="!isInEditMode"
                        v-bind:class="{ 'btn-loading': isLoading, }"
                        v-on:click="isInEditMode = true">
                    ✏️
                </button>
                <span v-b-tooltip="groups.length > 0 ? `Still used by ${ groups.length } groups` : ''">
                    <button class="btn btn-sm btn-light border border-left-0"
                            style="border-top-left-radius: 0; border-bottom-left-radius: 0; "
                            v-if="!isInEditMode"
                            v-bind:class="{ 'btn-loading': isLoading, }"
                            v-bind:disabled="groups.length > 0"
                            v-on:click="remove">
                    🗑️
                    </button>
                </span>

            </div>
        </div>
    </li>
</template>

<script>
import { BFormInput, VBTooltip, } from "bootstrap-vue";
export default {
    name: "CategoryItem",
    components: {
        BFormInput,
        ColorDot: () => import("../../ColorDot"),
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    props: {
        category: {
            type: Object,
        },
        index: {
            type: Number,
        },
        categories: {
            type: Array,
        },
    },
    data () {
        return {
            isLoading: false,
            isInEditMode: false,

            categoryNameLocal: "",
            categoryColorLocal: "",
            categoryRegionLocal: "",
        };
    },
    computed: {
        isActive () {
            return this.category?.isActive ?? true;
        },
        length () {
            return this.categories.length;
        },
        categoryName: {
            get () {
                return this.categoryNameLocal || this.category?.name;
            },
            set (val) {
                this.categoryNameLocal = val;
            }
        },
        categoryColor: {
            get () {
                return this.categoryColorLocal || this.category?.color;
            },
            set (val) {
                this.categoryColorLocal = val;
            }
        },
        categoryRegion: {
            get () {
                if (this.categoryRegionLocal) {
                    return this.$store.getters["region/itemByCode"](this.categoryRegionLocal);
                }
                return this.$store.getters["region/itemByCode"](this.category?.region);
            },
            set (val) {
                this.categoryRegionLocal = val;
            }
        },
        regions () {
            return this.$store.getters["region/itemsActive"];
        },
        groups () {
            return this.$store.getters["group/itemsByCategory"](this.category?._id);
        },
    },
    methods: {
        async moveUp () {
            if (this.index === 0) {
                return;
            }

            const temp = this.categories.slice(0);
            const original = temp[this.index - 1];
            temp[this.index - 1] = temp[this.index]
            temp[this.index] = original;

            try {
                await Promise.allSettled(
                    temp.map(
                        async ({ _id, order }, ind) => {
                            if (order !== ind) {
                                return this.$store.dispatch("group/editCategory", { _id, "order": ind });
                            }
                            return undefined;
                        }
                    )
                );
            } catch (e) {
                console.error(e);
            }
        },
        async moveDown () {
            if (this.index === this.categories.length - 1) {
                return;
            }

            const temp = this.categories.slice(0);
            const original = temp[this.index + 1];
            temp[this.index + 1] = temp[this.index]
            temp[this.index] = original;

            try {
                await Promise.allSettled(
                    temp.map(
                        async ({ _id, order }, ind) => {
                            if (order !== ind) {
                                return this.$store.dispatch("group/editCategory", { _id, "order": ind });
                            }
                            return undefined;
                        }
                    )
                );
            } catch (e) {
                console.error(e);
            }
        },
        cancel () {
            this.isInEditMode = false;
            this.categoryNameLocal = "";
            this.categoryColorLocal = "";
        },
        async toggle () {
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "group/editCategory",
                    {
                        "_id": this.category._id,
                        "isActive": !this.category?.isActive ?? false,
                    }
                );
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async edit () {
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "group/editCategory",
                    {
                        "_id": this.category._id,
                        "name": this.categoryName,
                        "color": this.categoryColor,
                    }
                );
                this.isInEditMode = false;
                this.categoryNameLocal = "";
                this.categoryColorLocal = "";
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async remove () {
            if (this.groups.length > 0) {
                return;
            }
            try {
                this.isLoading = true;
                await this.$store.dispatch("group/deleteCategory", this.category._id);
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.input-color {
    max-width: 70px;
}

.text-line-through {
    text-decoration: line-through;
    text-decoration-thickness: 3px;
}
</style>
